.file-upload-overlay {
  position: fixed;
  background-color: #00000015;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.file-upload-progress-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
  text-align: left;
  z-index: 99;
}

.file-upload-progress-header {
  border-bottom: 1px solid #00000015;
  padding: 12px 10px;
}

.file-upload-progress-wrapper h3 {
  margin: 0;
}

.file-upload-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #00000015;
}

.file-upload-card:last-child {
  border-bottom: none;
}

.file-upload-card .left {
  display: flex;
  gap: 10px;
}

.file-upload-card .left img {
  width: 30px;
  height: 30px;
}

.file-upload-card .left h5 {
  margin: 0;
}

.file-upload-card .left .subtle-text {
  margin: 0;
  color: #00000060;
  font-size: 12px;
}
